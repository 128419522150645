@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}
li {
  list-style: none;
}
::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
  scroll-behavior: smooth;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5rem #f0f3f8;
  border-radius: 1rem;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #19a916;
  border-radius: 5rem;
  padding: 5rem;
  border: 0.1rem solid white;
  outline: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #01519b;
  cursor: pointer;
}

.ant-form-item-required::before {
  display: none !important;
}
.inputFaq {
  .ant-input-prefix {
    margin-right: 1rem !important;
  }
}
.panelHome {
  .ant-collapse-header {
    padding: 0rem !important;
  }
  .ant-collapse-content {
    background-color: #ffffff !important;
  }
}

// .mainPanel::after {
//   content: "";
//   clip-path: polygon(50% 50%, 0 0, 100% 0);
//   position: absolute;
//   bottom: -14%;
//   left: 50%;
//   width: 1.5rem;
//   height: 1.5rem;
//   background-color: #19a916;
//   transform: translateX(-50%);
// }
.menuMb {
  @media (max-width: 768px) {
    overflow: unset !important;
    display: block !important;
  }
}

.heroContent:hover > div > div h2 {
  color: #ffffff !important;
}
.heroContent:hover > div > div > p {
  color: #ffffff !important;
}

.heroContent:hover > a > .program > button {
  justify-content: center;
  padding: 1rem 0;
  width: 200px !important;
  background-color: #f0f3f8 !important;
  color: #19a916;
  transition: all 0.3s linear;
  animation-duration: 0.2s;
}
.trial {
  .ant-select-selector {
    height: 48px !important;
    align-items: center;
  }
  .ant-select-arrow {
    left: 1% !important;
  }
  .ant-select-selection-placeholder {
    padding-left: 1.2rem !important;
    font-size: 14px !important;
    font-weight: 400;
  }
  .ant-select-selection-search-input {
    height: 100% !important;
    padding-left: 1.2rem !important;
    font-size: 12px !important;
    font-weight: 400;
  }
  .ant-select-selection-item {
    padding-left: 1.2rem !important;
  }
}
.indus {
  transform: translateY(-20%) !important;
}
input {
  font-family: "Montserrat" !important;
}

.ant-form-item {
  @media (max-width: 856px) {
    margin-bottom: 10px !important;
  }
}
.ant-input {
  font-family: "Montserrat" !important;
}

.ant-modal-content {
  padding: 10px 10px 4px 10px !important;
}
.ant-select-selector {
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  padding: 0 5px !important;
  font-size: 12px !important;
}

.register_partner {
  .ant-select-selector {
    height: 48px !important;
    display: flex !important;
    align-items: center !important;
    // border: 1px solid #f0f3f8 !important;
    padding: 0 5px !important;
    font-size: 12px !important;
  }
}
.ant-checkbox-group {
  flex-direction: column;
  span {
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}
.asterisk {
  &::after {
    color: red;
    content: "*";
    margin-left: 0.4rem;
  }
}
